import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Title, TextBlock, Button,
} from '@lxlabs/change-ad-components';
import styled from 'styled-components';
import SideImage from './sideImage';

const Style = styled(Card)`
  ${({ fullWidth }) => (fullWidth) && `
    @media not screen and (max-width: 834px) {
      grid-column: 1 / 3;
    }
  `}
`;

const Image = styled(({ component, ...props }) => React.cloneElement(component, props))`
  min-height: 400px;

  img {
    object-fit: contain !important;
  }
`;

const InnerCard = styled(Card)`
  height: auto;
  margin: auto !important;
`;

const WorkCard = ({
  image, title, description, cta, fullWidth,
}) => (
  <Style fullWidth={fullWidth} halfWidth>
    <Image component={image} />

    {/* {image} */}
    <Title alignment="center" spacing={{ top: 'large', bottom: 'large' }}>{title}</Title>
    <TextBlock alignment="center" spacing={{ bottom: 'large' }}>{description}</TextBlock>
    {cta && (
      <InnerCard
        backgroundColor="white"
        padding={{
          top: 'large', bottom: 'large', left: 'large', right: 'large',
        }}
      >
        <TextBlock alignment="center" spacing={{ bottom: 'medium' }}>
          {cta.description}
        </TextBlock>
        <Button text="Find out more" href={cta.link} />
      </InnerCard>
    )}
  </Style>
);

WorkCard.propTypes = {
  image: PropTypes.oneOfType([SideImage]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  cta: PropTypes.shape({
    description: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    link: PropTypes.string,
  }),
  fullWidth: PropTypes.bool,
};

WorkCard.defaultProps = {
  cta: null,
  fullWidth: false,
};

export default WorkCard;
